import React, { FC, useState, useEffect, useRef, useContext } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { PageContext } from '@agria/theme-brochureware/src/context/pageContext';
import type { PageProps } from 'gatsby';
import { Container } from '../../container/Container';
import { LinkTo } from '../../linkTo/LinkTo';
import {
  BascLogo,
  GccfLogo,
  Logo,
  DogFriendlyLogo,
  CruftsLogo,
  InsureK9Logo,
  RdaLogo,
  CtmLogo,
} from '../../svg';
import { LogoSmall } from '../../svg/svgs/LogoSmall';
import { Link } from '../../link/Link';
import {
  ArrowLeft,
  Contact,
  Menu,
  Close,
  ExpandSmall,
  Logout,
} from '../../icon';
import { useResponsiveType } from '../../../utils';
import { Button, StyledButton } from '../../button';
import { Typography } from '../../typography/Typography';
import { useHeader } from '../../../context/HeaderContext';
import { telephoneNumbers } from '../../../const/telephoneNumbers';
import { siteLinks } from '../../../const/siteLinks';
import token from './token';
import { useImages } from '../../../hooks/useImages';
import { Image } from '../../Image/Image';
import { useSiteMetadata } from '../../../hooks/useSiteMetadata';

export interface HeaderProps {
  brand?:
    | 'Agria'
    | 'BASC'
    | 'GCCF'
    | 'Dog Friendly'
    | 'Crufts'
    | 'Insure K9'
    | 'RDA'
    | 'Clegg Gifford'
    | 'CTM'
    | 'Service Dogs';
  navigationItems: NavigationItem[];
  secondaryNavigationItems: SecondaryNavigationItem[];
  enableMyAccount?: boolean;
  contactNumber?: string;
  myAccountLogout?: () => void; // Function to logout user
  loggedIn?: boolean; // Set to true when user is in my account context
  isMyAccount?: boolean; // Set to true when within my-account routes
  ctaText?: string;
  ctaLink?: string;
  siteId?: number;
  stickyCallToAction?: string;
  overrideStickyCTAButton?: {
    label?: string;
    url?: string;
    target?: string;
  };
  location?: PageProps['location'];
}

export interface NavigationItem {
  hideTopLevelNavigation: boolean;
  hideTelephoneNumber: boolean;
  ctaOverride: {
    label: string;
    target: string | null;
    url: string;
  } | null;
  link: {
    label: string;
    target: string | null;
    url: string;
  };
  headerChildItems?: headerChildItems[];
  telephoneNumberOverride?: string;
}

interface headerChildItems {
  link: {
    label: string;
    target: string | null;
    url: string;
  };
  navigationChildItems: SecondaryNavigationItem[];
}

interface SecondaryNavigationItem {
  link: {
    label: string;
    target: string | null;
    url: string;
  };
}

interface StyledMobileNavProps {
  isOpen: boolean;
}

interface StyledChildNavProps {
  isOpen: boolean;
}

interface ChildNavDropdownProps {
  childItem: headerChildItems;
  isMobile?: boolean;
}

interface StyledStickCtaProps {
  show: boolean;
}

const StyledHeader = styled.header`
  ${({ theme: { space } }) => css``};
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledLogo = styled(LinkTo)`
  ${({ theme: { space, maxMedia } }) => css`
    flex: 1 0 auto;

    @media (max-width: ${maxMedia.xLarge}) {
      flex: 1;
    }
  `}
`;

const StyledDiv = styled.div`
  ${({ theme: { space, maxMedia } }) => css`
    flex: 1 0 auto;

    @media (max-width: ${maxMedia.xLarge}) {
      flex: 1;
    }
  `}
`;

const StyledBrandLogo = styled.div`
  ${({ theme: { space, colors, maxMedia } }) => css`
    padding-left: ${space.large};
    border-left: solid 1px ${colors.brandTwo20};
    height: 60px;

    svg {
      height: 100%;
    }

    img {
      max-width: 100%;
    }

    @media (max-width: ${maxMedia.small}) {
      padding-left: ${space.xSmall};
      height: 48px;
    }

    @media (max-width: ${maxMedia.xLarge}) {
      flex: 1;
    }
  `}
`;

const StyledBrandWideImageLogo = styled.div`
  ${({ theme: { space, colors, maxMedia } }) => css`
    padding-left: ${space.large};
    border-left: solid 1px ${colors.brandTwo20};
    height: 50px;

    .gatsby-image-wrapper {
      img {
        object-fit: contain !important;
      }

      @media (max-width: ${maxMedia.medium}) {
        width: 100% !important;
        height: 50px !important;
      }
    }

    @media (max-width: ${maxMedia.small}) {
      padding-left: ${space.xSmall};
      height: 48px;
    }

    @media (max-width: ${maxMedia.xLarge}) {
      flex: 1;
    }
  `}
`;

const StyledLogoMobile = styled.span`
  ${({ theme: { media } }) => css`
    display: block;
    max-width: 200px;
    max-height: 48px;

    svg {
      max-width: 200px;
      width: 100%;
    }

    @media (min-width: ${media.large}) {
      display: none;
    }
  `};
`;

const StyledLogoDesktop = styled.span`
  ${({ theme: { maxMedia } }) => css`
    @media (max-width: ${maxMedia.large}) {
      display: none;
    }
  `};
`;

const StyledMenuIcon = styled.div`
  ${({ theme: { space, maxMedia } }) => css`
    display: none;

    @media (max-width: ${maxMedia.large}) {
      display: flex;
      flex: 0 0 auto;
      gap: ${space.large};
      margin-left: ${space.small};
    }

    @media (max-width: ${maxMedia.small}) {
      gap: ${space.small};
    }
  `}
`;

const StyledOpen = styled(Menu)`
  ${({ theme: { space } }) => css`
    cursor: pointer;
  `}
`;

const StyledClose = styled.div`
  ${({ theme: { space } }) => css`
    display: flex;
    gap: ${space.xxSmall};
    align-items: center;
    cursor: pointer;
  `}
`;

const StyledMobileNavContainer = styled.div<StyledMobileNavProps>`
  ${({ theme: { layers }, isOpen }) => css`
    display: ${isOpen ? 'block' : 'none'};
    position: absolute;
    top: 112px;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    z-index: ${layers.modalOverlay};
  `}
`;

const StyledMobileNav = styled.div`
  ${({ theme: { space, colors, media } }) => css`
    overflow-y: auto;
    max-height: calc(100% - 301px);
    > ul {
      padding: ${space.medium} ${space.medium} 0;

      > li {
        margin-bottom: ${space.small};

        @media (min-width: ${media.xSmall}) {
          margin-bottom: ${space.medium};
        }

        &:last-child {
          padding-bottom: ${space.medium};
          border-bottom: solid 1px ${colors.brandOne10};
        }

        > a {
          text-decoration: none;
          ${useResponsiveType('headingSmall')};

          @media (min-width: ${media.xSmall}) {
            ${useResponsiveType('headingMedium')};
          }

          &.is-active {
            color: ${colors[token.hoverColour]};
          }
        }

        > ul {
          padding-top: ${space.small};
          gap: ${space.xxSmall};

          li {
            div {
              li {
                margin-bottom: ${space.xxSmall};
              }
            }

            a {
              text-decoration: none;
              ${useResponsiveType('bodyMedium')};

              &.is-active {
                color: ${colors[token.hoverColour]};
              }
            }
          }
        }
      }
    }
  `}
`;

const StyledSecondaryMobileNav = styled.div`
  ${({ theme: { space, colors, layers } }) => css`
    padding: 0 ${space.medium} ${space.medium};

    ul {
      display: flex;
      flex-direction: column;
      gap: ${space.xxSmall};

      li {
        a {
          text-decoration: none;
          ${useResponsiveType('bodyMedium')};
        }
      }
    }
  `}
`;

const StyledSecondaryMobileBottomButtons = styled.div`
  ${({ theme: { space, colors } }) => css`
    padding: ${space.medium} ${space.medium};
    border-top: solid 1px ${colors.brandOne10};
    display: flex;
    flex-direction: column;
    gap: ${space.xSmall};
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 1;
  `}
`;

const StyledTopLevelNav = styled.div`
  ${({ theme: { colors, space, maxMedia } }) => css`
    background-color: ${colors.brandOne100};

    a {
      color: ${colors.brandOne20};
      text-decoration: none;
      padding: ${space.small};
      display: inline-flex;
      ${useResponsiveType('bodySmall')};
      line-height: 24px; // Align hights to links with icons

      &:hover {
        color: ${colors.utilityWhite};
      }
    }

    @media (max-width: ${maxMedia.large}) {
      display: none;
    }
  `};
`;

const StyledTopLeveContainer = styled(Container)`
  ${({ theme: { colors, space, maxMedia } }) => css`
    display: flex;
    justify-content: space-between;
  `};
`;

const StyledTopLevelNavPrimary = styled.nav`
  ${({ theme: { colors, space, maxMedia } }) => css`
    ul {
      display: flex;

      a {
        &.is-active {
          background-color: ${colors.utilityWhite};
          color: ${colors.brandOne100};
          cursor: default;

          &:hover {
            color: ${colors.brandOne100};
          }
        }

        &:hover {
          color: ${colors.utilityWhite};
        }
      }
    }
  `};
`;

const StyledTopLevelNavSecondary = styled.nav`
  ${({ theme: { colors, space, maxMedia } }) => css`
    ul {
      display: flex;

      a {
        padding: ${space.small} ${space.xSmall};

        &:hover {
          color: ${colors.utilityWhite};
        }
      }
    }
  `};
`;

const StyledMyAccountLink = styled.li`
  ${({ theme: { colors, space, maxMedia } }) => css`
    div {
      padding-left: ${space.medium};
      border-left: solid 1px ${colors.brandOne80};
    }
  `};
`;

const StyledBottomLevelNav = styled.div`
  ${({ theme: { colors, space, maxMedia } }) => css`
    padding: ${space.large} 0;
  `};
`;

const StyledBottomLevelNavItems = styled.nav`
  ${({ theme: { colors, space, maxMedia } }) => css`
    a,
    button {
      text-decoration: none;
      min-height: ${space.large};
      padding: 0 ${space.large};
      display: inline-flex;
      ${useResponsiveType('bodyMediumBold')};
      align-items: center;

      &:hover {
        color: ${colors[token.hoverColour]};
      }

      &.is-active {
        color: ${colors[token.hoverColour]};
      }
    }
    > ul {
      display: flex;
      position: relative;
    }

    @media (max-width: ${maxMedia.large}) {
      display: none;
    }
  `};
`;

const StyledChildNav = styled.ul<StyledChildNavProps>`
  ${({ theme: { colors, space, layers, borderRadii, media }, isOpen }) => css`
    display: flex;
    flex-direction: column;

    svg {
      transition: transform 0.2s ease;
      transform: ${isOpen ? 'rotate(0deg)' : 'rotate(180deg)'};
    }

    @media (min-width: ${media.large}) {
      display: ${isOpen ? 'flex' : 'none'};
      position: absolute;
      width: 288px;
      background-color: ${colors.utilityWhite};
      padding: ${space.large} ${space.xxSmall} ${space.large} ${space.large};
      z-index: ${layers.headerSearch};
      border-bottom-left-radius: ${borderRadii.medium};
      border-bottom-right-radius: ${borderRadii.medium};
      box-shadow: 4px 16px 32px -8px rgba(3, 36, 62, 0.16);

      li {
        a {
          padding: ${space.small};

          &.is-active {
            color: ${colors[token.hoverColour]};
          }
        }
      }
    }
  `};
`;

const StyledBottomLevelNavPrimary = styled.div`
  ${({ theme: { colors, space, maxMedia } }) => css`
    display: flex;
    align-items: center;
    gap: ${space.large};

    @media (max-width: ${maxMedia.small}) {
      margin-right: ${space.small};
      gap: ${space.small};
    }
  `};
`;

const StyledBottomLevelNavActions = styled.div`
  ${({ theme: { colors, space, maxMedia } }) => css`
    display: flex;
    align-items: center;
    gap: ${space.large};

    a {
      ${useResponsiveType('bodyMediumBold')};
    }

    @media (max-width: ${maxMedia.large}) {
      display: none;
    }
  `};
`;

const StyledContact = styled.div`
  ${({ theme: { space, maxMedia } }) => css`
    display: flex;
    align-items: center;
    flex: 1 0 auto;
    gap: ${space.xxSmall};
  `};
`;

const StyledChildNavToggle = styled.button<StyledChildNavProps>`
  ${({ isOpen }) => css`
    svg {
      transition: transform 0.2s ease;
      transform: ${isOpen ? 'rotate(0deg)' : 'rotate(180deg)'};
    }
  `};
`;

const StyledStickyCta = styled.div<StyledStickCtaProps>`
  ${({ theme: { colors, space, media, layers }, show }) => css`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: ${space.small} ${space.medium};
    background-color: ${colors.utilityWhite};
    z-index: ${layers.stickyCTA};
    box-shadow: 0px 0px 12px -3px rgba(0, 0, 0, 0.4);
    transition: transform 0.3s ease-in-out;
    transform: ${show ? 'translateY(0)' : 'translateY(100%)'};

    ${StyledButton} {
      width: 100%;
    }

    @media (min-width: ${media.large}) {
      display: none;
    }
  `};
`;

const findTopLevelNavItem = (
  navItems: NavigationItem[],
  currentPath: string | undefined
) => {
  if (!navItems || !currentPath) return null;

  // Remove any trailing slashes from the currentPath
  const cleanPath = currentPath.replace(/\/$/, '');

  // If the path is '/', return the first item
  if (cleanPath === '' && navItems.length > 0) {
    return navItems[0] || null;
  }

  // Split the path into parts using '/'
  const pathParts = cleanPath.split('/');

  // The first part should be what you're looking for
  const topLevelLink = `/${pathParts[1]}/`;

  // Find the top-level navigation item with a matching link
  const activePage = navItems?.find((item) => item.link.url === topLevelLink);

  if (!activePage) {
    return navItems[0] || null;
  }
  return activePage;
};

const ChildNavDropdown: React.FC<ChildNavDropdownProps> = ({
  childItem,
  isMobile,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { setIsMobileNavOpen } = useHeader();

  const toggleChildNav = () => {
    setIsOpen(!isOpen);
  };

  const handleClickChildItem = () => {
    setIsOpen(false);
    setIsMobileNavOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    // Attach click event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef}>
      {!isMobile && (
        <StyledChildNavToggle
          onClick={toggleChildNav}
          type="button"
          isOpen={isOpen}
          aria-expanded={isOpen}
        >
          {childItem.link.label}
          <ExpandSmall />
        </StyledChildNavToggle>
      )}
      <StyledChildNav isOpen={isOpen}>
        {childItem.navigationChildItems?.map(
          (grandChildItem, grandChildIndex) =>
            grandChildItem?.link ? (
              <li key={grandChildIndex}>
                <LinkTo
                  to={grandChildItem.link.url}
                  onClick={handleClickChildItem}
                >
                  {grandChildItem.link.label}
                </LinkTo>
              </li>
            ) : null
        )}
      </StyledChildNav>
    </div>
  );
};

const getContactNumberByBrand = (brand: string | undefined): string => {
  switch (brand) {
    case 'BASC':
      return '03330 30 82 05';
    case 'GCCF':
      return '03330 30 90 30';
    case 'Dog Friendly':
      return '03330 30 82 92';
    case 'Crufts':
      return '03330 30 82 89';
    case 'Insure K9':
      return '03330 30 82 08';
    case 'RDA':
      return '03330 30 83 88';
    case 'Clegg Gifford':
      return '01481 72 89 87';
    case 'CTM':
      return '03330 30 83 62';
    case 'Service Dogs':
      return '';
    case 'Puppies':
      return '03330 30 83 65';
    case 'Pets4Homes':
      return '03330 30 83 78';
    default:
      return telephoneNumbers.newSales;
  }
};

const getQuoteUrlByBrand = (
  brand: string | undefined,
  ctaLink?: string
): string => {
  const baseQuoteUrl = ctaLink || siteLinks.quoteAndBuy;
  let trackingParam = '';

  switch (brand) {
    case 'BASC':
      trackingParam = '?affid=17969&dog';
      break;
    case 'GCCF':
      trackingParam = '?affid=20275&cat';
      break;
    case 'Dog Friendly':
      trackingParam = '?affid=104408&dog';
      break;
    case 'Crufts':
      trackingParam = '?pc=crufts';
      break;
    case 'Insure K9':
      trackingParam = '';
      break;
    case 'RDA':
      trackingParam =
        '?affid=08D8E393-BB21-44EC-8FF4-1CDB91CB5EC9&_gl=1*jaaq9b*_gcl_aw*R0NMLjE2OTcwMzI5ODAuQ2owS0NRandqNW1wQmhESkFSSXNBT1ZqQmRvTG9ZRkwwS3ViRWpCYkJXRWM3T3R3ekI3N2F6TVRlTjV3V0xUdEVNU3N4OWFEVTdoMzNxZ2FBa1FURUFMd193Y0I.*_gcl_dc*R0NMLjE2OTcwMzI5ODAuQ2owS0NRandqNW1wQmhESkFSSXNBT1ZqQmRvTG9ZRkwwS3ViRWpCYkJXRWM3T3R3ekI3N2F6TVRlTjV3V0xUdEVNU3N4OWFEVTdoMzNxZ2FBa1FURUFMd193Y0I.*_gcl_au*MjA5MDcwMTcyMy4xNjkxNDA0MzIxLjgxMTI2ODY5MC4xNjk1ODk4NDEyLjE2OTU4OTg0MTI';
      break;
    case 'Clegg Gifford':
      trackingParam = '?affid=185980';
      break;
    case 'CTM':
      trackingParam = '';
      break;
    default:
      trackingParam = '';
      break;
  }

  return `${baseQuoteUrl}${trackingParam}`;
};

export const Header: FC<HeaderProps> = ({
  brand,
  siteId,
  navigationItems,
  secondaryNavigationItems,
  enableMyAccount,
  myAccountLogout,
  loggedIn,
  isMyAccount,
  ctaText,
  ctaLink,
  location,
}) => {
  const [pathname, setPathname] = useState('');
  const [activePage, setActivePage] = useState<NavigationItem | null>(null);
  const lastScrollY = useRef(0);
  useEffect(() => {
    // This ensures that window is only accessed in the browser
    if (typeof window !== 'undefined') {
      setPathname(window.location.pathname);
      lastScrollY.current = window.scrollY;
    }
  }, []);

  useEffect(() => {
    setActivePage(findTopLevelNavItem(navigationItems, location?.pathname));
  }, [navigationItems, location]);

  const { companyName } = useSiteMetadata();

  const { cgLogo } = useImages();
  const isDualBrand =
    brand === 'BASC' ||
    brand === 'GCCF' ||
    brand === 'RDA' ||
    brand === 'Dog Friendly' ||
    brand === 'Crufts' ||
    brand === 'Insure K9' ||
    brand === 'CTM' ||
    brand === 'Clegg Gifford';

  const { isMobileNavOpen, setIsMobileNavOpen } = useHeader();

  const dynamicContactNumber = getContactNumberByBrand(brand);

  const [showStickyCta, setShowStickyCta] = useState(false);
  const headerRef = useRef<HTMLDivElement>(null);

  const { stickyCallToAction, overrideStickyCTAButton } =
    useContext(PageContext)!;

  useEffect(() => {
    const preventBodyScroll = () => {
      document.body.classList.add('prevent-body-scroll');
    };

    if (isMobileNavOpen) {
      preventBodyScroll();
    } else {
      document.body.classList.remove('prevent-body-scroll');
    }

    return () => {
      document.body.classList.remove('prevent-body-scroll');
    };
  }, [isMobileNavOpen]);

  useEffect(() => {
    // to keep it clean, close mobile nav if window is resized above mobile menu breakpoint
    const checkWindowSize = () => {
      if (window.innerWidth > 991) {
        setIsMobileNavOpen(false);
      }
    };

    window.addEventListener('resize', checkWindowSize);

    return () => {
      window.removeEventListener('resize', checkWindowSize);
    };
  }, [setIsMobileNavOpen]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const headerHeight = headerRef.current
        ? headerRef.current.offsetHeight
        : 0;

      // Determine if the header is out of view
      const headerOutOfView = currentScrollY > headerHeight;

      // Show the CTA if:
      // 1. Scrolling up and past the Header
      // 2. Scrolling down and the Header is out of view
      const shouldShowStickyCta =
        (currentScrollY < lastScrollY.current &&
          currentScrollY > headerHeight) ||
        (currentScrollY > lastScrollY.current && headerOutOfView);

      setShowStickyCta(shouldShowStickyCta);

      // Update the last scroll position
      lastScrollY.current = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  // Sticky CTA

  const renderStickyButton = () => {
    if (isMyAccount && enableMyAccount && loggedIn) {
      return (
        <StyledStickyCta show={showStickyCta}>
          <Button
            label="Log out"
            small
            onClick={myAccountLogout}
            variant="secondaryOutline"
            iconLeft={<Logout />}
          />
        </StyledStickyCta>
      );
    }
    if (!isMyAccount) {
      return (
        <StyledStickyCta show={showStickyCta}>
          <Button
            url={
              overrideStickyCTAButton?.url ||
              activePage?.ctaOverride?.url ||
              getQuoteUrlByBrand(brand)
            }
            label={
              overrideStickyCTAButton?.label ||
              activePage?.ctaOverride?.label ||
              'Get a quote'
            }
            arrowRight
            small
            {...(overrideStickyCTAButton?.target && {
              target: overrideStickyCTAButton.target,
            })}
          />
        </StyledStickyCta>
      );
    }
    return null;
  };

  return (
    <>
      <StyledHeader ref={headerRef}>
        {!isDualBrand && (
          <StyledTopLevelNav>
            <StyledTopLeveContainer>
              {/* Top level nav */}
              {!activePage?.hideTopLevelNavigation && !isMyAccount ? (
                <>
                  <StyledTopLevelNavPrimary aria-label="Main Navigation">
                    {navigationItems && navigationItems.length > 1 && (
                      <ul>
                        {navigationItems?.map((item, index) => {
                          if (!item.hideTopLevelNavigation) {
                            return (
                              <li key={index}>
                                <LinkTo
                                  to={item.link.url}
                                  className={
                                    item.link.url === activePage?.link.url
                                      ? 'is-active'
                                      : ''
                                  }
                                >
                                  {item.link.label}
                                </LinkTo>
                              </li>
                            );
                          }
                          return null;
                        })}
                      </ul>
                    )}
                  </StyledTopLevelNavPrimary>
                  <StyledTopLevelNavSecondary aria-label="Secondary Navigation">
                    <ul>
                      {secondaryNavigationItems?.map((item, index) => (
                        <li key={index}>
                          <LinkTo to={item.link.url}>{item.link.label}</LinkTo>
                        </li>
                      ))}
                      {enableMyAccount && (
                        <StyledMyAccountLink>
                          <Link url="/my-account/login/" label="My Account" />
                        </StyledMyAccountLink>
                      )}
                    </ul>
                  </StyledTopLevelNavSecondary>
                </>
              ) : (
                <p>
                  <Link
                    url="/"
                    label="Back to the main site"
                    icon={<ArrowLeft size="small" />}
                  />
                </p>
              )}
            </StyledTopLeveContainer>
          </StyledTopLevelNav>
        )}

        <StyledBottomLevelNav>
          <StyledContainer>
            <StyledBottomLevelNavPrimary>
              {brand === 'Insure K9' ? (
                <StyledDiv>
                  <StyledLogoMobile>
                    <LogoSmall />
                  </StyledLogoMobile>
                  <StyledLogoDesktop>
                    <Logo />
                  </StyledLogoDesktop>
                </StyledDiv>
              ) : (
                <StyledLogo to="/" title={`${companyName} homepage`}>
                  <StyledLogoMobile>
                    <LogoSmall title={`${companyName} homepage`} aria-hidden="true" />
                  </StyledLogoMobile>
                  <StyledLogoDesktop>
                    <Logo title={`${companyName} homepage`} aria-hidden="true" />
                  </StyledLogoDesktop>
                </StyledLogo>
              )}

              {brand === 'GCCF' && (
                <StyledBrandLogo>
                  <GccfLogo />
                </StyledBrandLogo>
              )}

              {brand === 'BASC' && (
                <StyledBrandLogo>
                  <BascLogo />
                </StyledBrandLogo>
              )}

              {brand === 'Dog Friendly' && (
                <StyledBrandLogo>
                  <DogFriendlyLogo />
                </StyledBrandLogo>
              )}

              {brand === 'Crufts' && (
                <StyledBrandLogo>
                  <CruftsLogo />
                </StyledBrandLogo>
              )}

              {brand === 'Insure K9' && (
                <StyledBrandLogo>
                  <InsureK9Logo />
                </StyledBrandLogo>
              )}

              {brand === 'RDA' && (
                <StyledBrandLogo>
                  <RdaLogo />
                </StyledBrandLogo>
              )}

              {brand === 'Clegg Gifford' && (
                <StyledBrandWideImageLogo>
                  <Image src={cgLogo} />
                </StyledBrandWideImageLogo>
              )}

              {brand === 'CTM' && (
                <StyledBrandLogo>
                  <CtmLogo />
                </StyledBrandLogo>
              )}

              {/* Second level nav */}

              {activePage && !isDualBrand && !isMyAccount && (
                <StyledBottomLevelNavItems aria-label="Main Navigation Second Level">
                  {activePage.headerChildItems && (
                    <ul>
                      {activePage.headerChildItems?.map(
                        (childItem, childIndex) => (
                          <li key={childIndex}>
                            {childItem?.navigationChildItems &&
                            childItem?.navigationChildItems.length > 0 ? (
                              <ChildNavDropdown childItem={childItem} />
                            ) : (
                              <LinkTo to={childItem?.link.url}>
                                {childItem?.link.label}
                              </LinkTo>
                            )}
                          </li>
                        )
                      )}
                    </ul>
                  )}
                </StyledBottomLevelNavItems>
              )}
            </StyledBottomLevelNavPrimary>
            <StyledBottomLevelNavActions>
              {!activePage?.hideTelephoneNumber && brand !== 'Service Dogs' && (
                <StyledContact>
                  <Contact color="brandOne100" />
                  {isMyAccount && (
                    <a
                      href={`tel:${
                        activePage?.telephoneNumberOverride
                          ? activePage?.telephoneNumberOverride?.replace(
                              /\s/g,
                              ''
                            )
                          : telephoneNumbers.customerServices.replace(/\s/g, '')
                      }`}
                      aria-label={
                        activePage?.telephoneNumberOverride ||
                        telephoneNumbers.customerServices
                      }
                    >
                      {activePage?.telephoneNumberOverride ||
                        telephoneNumbers.customerServices}{' '}
                    </a>
                  )}
                  {!isMyAccount &&
                    dynamicContactNumber &&
                    !activePage?.hideTelephoneNumber && (
                      <a
                        href={`tel:${dynamicContactNumber?.replace(/\s/g, '')}`}
                        aria-label={dynamicContactNumber}
                      >
                        {dynamicContactNumber}
                      </a>
                    )}
                </StyledContact>
              )}

              {isMyAccount && enableMyAccount && loggedIn && (
                <Button
                  label="Log out"
                  small
                  onClick={myAccountLogout}
                  variant="secondaryOutline"
                  iconLeft={<Logout />}
                />
              )}

              {!isMyAccount &&
                brand !== 'Insure K9' &&
                brand !== 'Service Dogs' && (
                  <>
                    {activePage?.ctaOverride && (
                      <Button
                        url={activePage.ctaOverride.url}
                        label={activePage.ctaOverride.label}
                        arrowRight
                        small
                      />
                    )}
                    {!activePage?.ctaOverride && siteId !== 3162 && (
                      <Button
                        url={getQuoteUrlByBrand(brand, ctaLink)}
                        label={ctaText || 'Get a quote'}
                        arrowRight
                        small
                      />
                    )}
                  </>
                )}
            </StyledBottomLevelNavActions>
            <StyledMenuIcon>
              {!activePage?.hideTelephoneNumber && brand !== 'Service Dogs' && (
                <StyledContact>
                  {isMyAccount && (
                    <a
                      href={`tel:${
                        activePage?.telephoneNumberOverride
                          ? activePage?.telephoneNumberOverride.replace(
                              /\s/g,
                              ''
                            )
                          : telephoneNumbers.customerServices?.replace(
                              /\s/g,
                              ''
                            )
                      }`}
                      aria-label={telephoneNumbers.customerServices}
                    >
                      <Contact color="brandOne100" title="Contact us" />
                    </a>
                  )}
                  {!isMyAccount && dynamicContactNumber && (
                    <a
                      href={`tel:${dynamicContactNumber?.replace(/\s/g, '')}`}
                      aria-label={dynamicContactNumber}
                    >
                      <Contact color="brandOne100" title="Contact us" />
                    </a>
                  )}
                </StyledContact>
              )}
              {siteId !== 3162 && (
                <button
                  type="button"
                  onClick={toggleMobileNav}
                  aria-label="Toggle navigation"
                  aria-expanded={isMobileNavOpen}
                >
                  {isMobileNavOpen ? (
                    <StyledClose>
                      <Typography>Close</Typography>
                      <Close />
                    </StyledClose>
                  ) : (
                    <StyledOpen />
                  )}
                </button>
              )}
            </StyledMenuIcon>
            <StyledMobileNavContainer isOpen={isMobileNavOpen}>
              <StyledMobileNav className="mobile-nav">
                {navigationItems && navigationItems.length > 0 && (
                  <ul>
                    {navigationItems?.map((item, index) => (
                      <li key={index}>
                        <LinkTo to={item.link.url} onClick={toggleMobileNav}>
                          {item.link.label}
                        </LinkTo>
                        {item?.headerChildItems &&
                          item?.headerChildItems?.length > 0 &&
                          item.link.url === activePage?.link.url && (
                            <ul>
                              {item?.headerChildItems.map(
                                (childItem, childIndex) => (
                                  <li key={childIndex}>
                                    {childItem?.navigationChildItems?.length >
                                    0 ? (
                                      <ChildNavDropdown
                                        childItem={childItem}
                                        isMobile
                                      />
                                    ) : (
                                      <LinkTo
                                        to={childItem?.link.url}
                                        onClick={() =>
                                          setIsMobileNavOpen(false)
                                        }
                                      >
                                        {childItem?.link.label}
                                      </LinkTo>
                                    )}
                                  </li>
                                )
                              )}
                            </ul>
                          )}
                      </li>
                    ))}
                  </ul>
                )}
                {secondaryNavigationItems &&
                  secondaryNavigationItems.length > 0 && (
                    <StyledSecondaryMobileNav>
                      <ul>
                        {secondaryNavigationItems?.map((item, index) => (
                          <li key={index}>
                            <LinkTo
                              to={item.link.url}
                              onClick={() => setIsMobileNavOpen(false)}
                            >
                              {item.link.label}
                            </LinkTo>
                          </li>
                        ))}
                      </ul>
                    </StyledSecondaryMobileNav>
                  )}
              </StyledMobileNav>
              <StyledSecondaryMobileBottomButtons>
                {enableMyAccount && loggedIn ? (
                  <Button
                    label="Log out"
                    onClick={myAccountLogout}
                    variant="secondaryOutline"
                    iconLeft={<Logout />}
                  />
                ) : (
                  ''
                )}

                {enableMyAccount && !loggedIn ? (
                  <Button
                    url="/my-account/login/"
                    label="My Account"
                    variant="secondaryOutline"
                    onClick={() => setIsMobileNavOpen(false)}
                  />
                ) : (
                  ''
                )}

                {brand !== 'Service Dogs' && (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <>
                    {activePage?.ctaOverride ? (
                      <Button
                        url={activePage.ctaOverride.url}
                        label={activePage.ctaOverride.label}
                        onClick={() => setIsMobileNavOpen(false)}
                        arrowRight
                      />
                    ) : (
                      <Button
                        url={getQuoteUrlByBrand(brand, ctaLink)}
                        label={ctaText || 'Get a quote'}
                        onClick={() => setIsMobileNavOpen(false)}
                        arrowRight
                      />
                    )}
                  </>
                )}
              </StyledSecondaryMobileBottomButtons>
            </StyledMobileNavContainer>
          </StyledContainer>
        </StyledBottomLevelNav>
      </StyledHeader>
      {stickyCallToAction !== 'Disabled' && <>{renderStickyButton()}</>}
    </>
  );
};

export default Header;
